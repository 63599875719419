/*
 * FuquaWorld Authentication 
 *
 * Uses JWT based authentication.
 * We do not store JWT at this time relying on cookies.. may need to change
 * to support mobile devices.
 * 
 * If AUTO is on-- and user is not logged in.. redirect user to login URL
 *   built in URL for PROD is https://go.fuqua.duke.edu/auth/onelink?service=[this.location]
 * 
 * Trust device.  If device is trusted, persist data to local storage and
 * leverage even when not authenticated.
 * Trust this device and store my data locally.
 * Accepting this toggle will allow some data to be stored locally such as
 * your profile without having to log in.  If someone else logs in, the data
 * will automatically be removed.
 * localhost: trusted.
 * 
 */
const FwProfile = (() => {
  'use strict';

  const FW_AUTHENTICATED = "_FW_AUTHENTICATED";                                 // Authenticated event -- user has successfully authenticated
  const FW_LOGGED_OUT = "_FW_LOGGED_OUT";                                       // Logged out event -- called after a user logout
  const FW_EXPIRES_SOON = "_FW_EXPIRES_SOON";                                   // Session expiring notification event
  const FW_EXPIRED = "_FW_EXPIRED";                                             // Session expired event
  const FW_LOGIN = "_FW_LOGIN";                                                 // Log in event -- to request a login
  const FW_LOGOUT = "_FW_LOGOUT";                                               // Log out event -- to request a logout

  let _auto = false;                                                            // Automatically push user to login if invalid
  let _authenticated = false;                                                   // Is authenticated
  let _profile = {};                                                            // User profile
  let _groups = [];                                                             // List of LDAP groups
  let _url = 'https://go.fuqua.duke.edu/auth/onelink?service=';                 // URL to log user into system
  let _lti = "";                                                                // Use LTI Authentication
  let _validateUrl = 'https://go.fuqua.duke.edu/auth/getjwt';                   // URL to validate JWT and get info
  let _notifyExpiry = 10;                                                       // Start notifications when session has less than n minutes left
  let _notifyFrequency = 30;  
  let _referer = null;
  // Send notifications every n seconds
  //let _jwt = null;
  
  const addEventListeners = () => {
    document.addEventListener(FW_LOGIN, loginEvent);
    document.addEventListener(FW_LOGOUT, logoutEvent);
    document.addEventListener(FW_EXPIRED, expiryEvent);
    document.addEventListener(FW_EXPIRES_SOON, expiryEvent);
    document.addEventListener(FW_AUTHENTICATED, authenticatedEvent);        
  };
  
  const removeEventListeners = () => {
    document.removeEventListener(FW_LOGIN, loginEvent);
    document.removeEventListener(FW_LOGOUT, logoutEvent);
    document.removeEventListener(FW_EXPIRED, expiryEvent);
    document.removeEventListener(FW_EXPIRES_SOON, expiryEvent);
    document.removeEventListener(FW_AUTHENTICATED, authenticatedEvent);      
  };
  
  const emitEvent = (eventName, eventDetails) => {
	let event = new CustomEvent( 
                eventName, { 'bubbles': true, 'composed': true, 'detail': eventDetails } );
        document.dispatchEvent(event);
  };
  
  const authUrl = () => { 
      return _url + encodeURI(_referer === null ? window.location.href: _referer); 
  };
  
  const isMemberOf = (criteria) => {                                            // Test to see if a provided CN or DN matches a group in user's list of groups
      if (_groups) {
          _groups.some((x) => { 
              if (criteria.indexOf("=") > 0) {
                  if (x.dn && x.dn.toLowerCase() === criteria.toLowerCase()) return true;
              }
              if (x.cn && x.cn.toLowerCase() === criteria.toLowerCase()) return true;
          });
      }
      return false;
  };
  
  const hasObjectClass = (objectclass) => {                                     // Test to see if the user's profile has the specified objectclass
      if (_profile && _profile.objectclasses) {
            _profile.objectclasses.some((x) => { 
              if (x.toLowerCase() === objectclass.toLowerCase()) return true;
            });              
      }
      return false;
  };
  
  const getProfile = () => { return _profile; };
  
  const getGroups = () => { return _groups; };
  
  const setAuto = (value) => { _auto = value; };
  
  const setUrl = (value) => { _url = value; };
  
  const setLti = (value) => { 
      _lti = value; 
      fetchJWT(false);
  };
    
  const getLti = () => { return _lti; };
  
  const setReferer = (value) => { _referer = value; };
  
  const getReferer = () => { return _referer; };
  
  const setValidateUrl = (value) => { _validateUrl = value; };
  
  const setNotifyExpiry = (value) => { _notifyExpiry = value; };
  
  const setNotifyFrequency = (value) => { _notifyFrequency = value; };
 
  const isAuthenticated = () => { return _authenticated; };
  
  const loginEvent = (e) => {
        if (_authenticated) _authenticated = false;
        initiateLogin();
  };

  const initiateLogin = () => { window.location.href = authUrl(); };
  
  const logoutEvent = (e) => { if (_authenticated) _authenticated = false; };
  
  const authenticatedEvent = (e) => { fetchJWT(false); };
  
  const expiryEvent = (e) => { console.log(JSON.stringify(e.detail)); };
  
  const fetchJWT = async (check) => {                                           // Perform the validation check and populate data
        if (!_authenticated) {
            if ((check && _auto) || !check) {
    		const response = _lti.length > 0 ? 
                                    await fetch(_validateUrl, {
                                        method: 'get',
                                        mode: 'cors',
                                        cache: 'no-cache',
                                        headers: {
                                            'Authorization': 'Bearer ' + _lti
                                        }
                                    }):
                                    await fetch(_validateUrl, {
                                        method: 'get',
                                        mode: 'cors',
                                        cache: 'no-cache',
                                        credentials: 'include'
                                    });
		if (response.status === 200) {
    			const json = await response.json();
                        //_jwt = json.jwt;
			_profile = json.profile ? json.profile: {};
                        _profile.exp = json.exp;
                        if (json.proxied) _profile.proxied = json.proxied;
        		_groups = json.groups ? json.groups: [];
			_authenticated = true;
			emitEvent(FW_AUTHENTICATED, {} );
                        // Show hidden elements on page...
                        try { 
                            let elems = document.getElementsByClassName("authblock");
                            for (let i = 0; i < elems.length; i++) elems[i].style.display="block"; 
                        } catch (p) { console.log(p); }
                        try { 
                            let elems = document.getElementsByClassName("authinline");
                            for (let i = 0; i < elems.length; i++) elems[i].style.display="inline";  
                        } catch (p) { console.log(p); }
                        try { 
                            let elems = document.getElementsByClassName("authvisible");
                            for (let i = 0; i < elems.length; i++) elems[i].style.visibility="visible"; 
                        } catch (p) { console.log(p); }
                        try { 
                            let elems = document.getElementsByClassName("authnone");
                            for (let i = 0; i < elems.length; i++) elems[i].style.display="none"; 
                        } catch (p) { console.log(p); }
                        try { 
                            let elems = document.getElementsByClassName("authhidden");
                            for (let i = 0; i < elems.length; i++) elems[i].style.display="hidden"; 
                        } catch (p) { console.log(p); }
                } else {
        		if (_auto && !_authenticated && !_lti.length > 0) {
                            initiateLogin();
        		}			
		}
            }
  	} 
  };
  
  const init = config => {
    fetchJWT(false);
    interval = window.setInterval(() => {
      console.log("interval...");
      if (_authenticated) {
        let now = new Date().getTime();
        if (!_profile || (_profile.exp && _profile.exp * 1000 < now)) {
            _authenticated = false;
            emitEvent(FW_EXPIRED, { 'expired_at': now });
            if (_auto) { initiateLogin(); }
        } else {
            if (_profile.exp * 1000 - now < 60000 * _notifyExpiry) {
                emitEvent(
                        FW_EXPIRES_SOON, 
                        { 'expires_at': _profile.exp * 1000, 'time_left': _profile.exp * 1000 - now } 
                );
            }
        }
      } 
    }, _notifyFrequency * 1000);

  };

  let interval = null; 
  
  return { 
      init, 
      addEventListeners, 
      removeEventListeners, 
      fetchJWT, 
      isAuthenticated, 
      getProfile, 
      getGroups, 
      setAuto, 
      setReferer,
      setUrl, 
      setLti,
      getLti,
      setValidateUrl, 
      setNotifyFrequency, 
      setNotifyExpiry 
  };
})();
export default FwProfile

